import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Get from '../Config/Get';
import Store from '../Store/Store';
import Config from '../Config/Config';
import { jwtDecode } from "jwt-decode";
import InputMask from 'react-input-mask';
import Validate from '../Config/Validate';
import { v4 as uuidv4 } from 'uuid';
export const ModalAddOrder2 = (props, ref) => {
    const [open, setOpen] = useState('');
    const [area, setArea] = useState([]);
    const [appeal, setAppeal] = useState([]);
    const [appealtype, setAppealType] = useState([]);
    const [user, setUser] = useState('');
    const [shects, setShets] = useState([]);

    const [form, setForm] = useState(
        {
            value: {
                areaRoomId: '',
                appealTypeId: '',
                description: '',
                personalAccountId: '',
                address: '',
                phoneNumber: '',
                contactPerson: '',
                userId: '',
                icon: []
            },

            error: {
                areaRoomId: '',
                appealTypeId: '',
                description: '',
                personalAccountId: '',
                address: '',
                phoneNumber: '',
                contactPerson: '',
                userId: ''
            },
            focus: {
                areaRoomId: '',
                appealTypeId: '',
                description: '',
                personalAccountId: '',
                address: '',
                phoneNumber: '',
                contactPerson: '',
                userId: ''
            }
        }
    );
    useImperativeHandle(ref, () => ({ modaOpen }));
    const setValue = (name, value) => {

        setForm({
            ...form,
            value: {
                ...form.value,
                [name]: value
            }, focus: {
                ...form.focus,
                [name]: value !== '' ? 'focus' : ''
            }, error: {
                ...form.error,
                [name]: ''
            }
        })
    }
    const modaOpen = (data) => {

        setOpen('open');
        setAppeal(data);

    }
    const modaClose = () => {
        setOpen('');
    }
    const getAppealType = async () => {
        if (Store.appealtypes.time < Date.now()) {
            let data = await Get.get('api/appeal-types?offset=0&count=100');
            Store.appealtypes.data = data.data;
            Store.appealtypes.time = Date.now() * 60 * 60 * 24;

        }
        setAppealType(Store.appealtypes.data);
    }
    const getAreas = async () => {

        if (Store.area.time < Date.now()) {

            let data = await Get.get('api/area-rooms?offset=0&count=100');
            Store.area.data = data.data;
            Store.area.time = Date.now() * 60 * 60 * 24;


        }
        if (Store.area.data[0] !== undefined) {

            setArea(Store.area.data);
            setForm(
                {
                    ...form,
                    value: {
                        ...form.value,
                        areaRoomId: Store.area.data[0].areaRoomId
                    }
                }
            );
        }
    }
    const getSchets = async () => {
        let data = [];

        if (Store.schets.time < Date.now()) {
            data = await Get.get('api/personal-accounts');
            if (data.status !== 404 && data.data !== undefined) {
                Store.schets.data = data.data;
                Store.schets.time = Date.now() * 60 * 60 * 24 * 7;
            }
        }

        //   console.log(Store.schets.data)
        if (Store.schets.data.length > 0) {
            setShets(Store.schets.data);



        }

    }
    const setAddress = (id) => {
        Store.schets.data.map((el, index) => {

            if (el.personalAccountId == id) {

                setValue('address', el.address)
            }
        });

    }
    const getUser = async () => {
        let tokenData = JSON.parse(localStorage.getItem('token'));
        let tokenDataDecode = {};
        if (typeof tokenData == 'object') {
            tokenDataDecode = jwtDecode(tokenData.token);

        }
        if (Store.user.time < Date.now()) {
            let data = await Get.get('api/users/' + tokenDataDecode.user_id);
            Store.user.data = data;
            Store.user.time = Date.now() * 60 * 60 * 24 * 365;


        }
        return Store.user.data;


    }
    const scroll = (block) => {
        setTimeout(function () {

            let element = document.getElementById(block);

            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }

        }, 100);
    }
    const inputFiles = async (e) => {
        let files = [...e.target.files];
        let images = await Promise.all(files.map(f => { return readAsDataURL(f) }));
        let iArr = form.value.icon;
        images.map((el, index) => {
            iArr.push(el.data);
        });
        setForm({
            ...form,
            value: {
                ...form.value,
                icon: iArr
            }
        })
    }
    const readAsDataURL = (file) => {
        return new Promise((resolve, reject) => {
            let fileReader = new FileReader();
            fileReader.onload = function () {
                return resolve({ data: fileReader.result, name: file.name, size: file.size, type: file.type });

            }
            fileReader.readAsDataURL(file);
        })
    }

    const removeImage = (index) => {

        form.value.icon.splice(index, 1);
        let files = form.value.icon;
        console.log(files)
        setForm({
            ...form,
            value: {
                ...form.value,
                icon: [...form.value.icon]
            }
        })
    }
    const createOrder = async (e) => {
        e.preventDefault();

        if (Validate.Empty(form.value.appealTypeId)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    appealTypeId: 'Выберите тип обращения'
                },
                focus: {
                    ...form.focus,
                    appealTypeId: 'focus error'
                }
            });
            scroll('scroolBlock1');
            return;
        }
        if (Validate.Empty(form.value.description)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    description: 'Заполните описание заявки'
                },
                focus: {
                    ...form.focus,
                    description: 'focus error'
                }
            });
            scroll('scroolBlock2');
            return;
        }


        let uuid = uuidv4();
        let data = await Get.post({
            requestId: uuid,
            description: form.value.description,
            contactPerson: form.value.contactPerson,
            phoneNumber: form.value.phoneNumber,
            appealCategoryId: appeal.appealCategoryId,
            appealTypeId: form.value.appealTypeId,
            areaRoomId: form.value.areaRoomId,
            userId: form.value.userId,
            icons: form.value.icon,
            personalAccountId: form.value.personalAccountId,
            statusId: 'ccd3c68a-9e01-41ce-98c0-5cee5d0350f7',
            inWork: true
        }, 'api/requests');
        if (data.type == 'success') {
            modaClose();
            props.close()
        }
    }
    if (shects.length !== 0) {
        if (form.value.address == '') {

            setForm(
                {
                    ...form,
                    value: {
                        ...form.value,
                        personalAccountId: shects[0].personalAccountId,
                        address: shects[0].address,

                    }
                }
            );
        }
        //  console.log('address', shects[0].address)
    }

    if (user !== null) {

        if (form.value.contactPerson == '' && user.name !== undefined) {

            setForm(
                {
                    ...form,
                    value: {
                        ...form.value,
                        contactPerson: user.name,
                        phoneNumber: user.phone,
                        userId: user.user_id

                    },
                    focus: {
                        ...form.focus,
                        contactPerson: 'focus',
                        phoneNumber: 'focus'
                    }
                }
            );
        }
        //  console.log('address', shects[0].address)
    }
    useEffect(() => {
        const as = async () => {
            let userdata = await getUser();


            let tokenData = JSON.parse(localStorage.getItem('token'));
            let tokenDataDecode = {};
            if (typeof tokenData == 'object') {
                tokenDataDecode = jwtDecode(tokenData.token);
                let user = {

                    name: tokenDataDecode.name,
                    phone: userdata.phoneNumber,
                    email: tokenDataDecode.email,
                    user_id: tokenDataDecode.user_id
                };

                if (!localStorage.getItem('user')) {
                    localStorage.setItem('user', JSON.stringify(user));
                } else {
                    user = JSON.parse(localStorage.getItem('user'));
                }

                await getSchets();

                setUser(user);
                getAppealType();
                getAreas();

            }
        }
        as();
    }, [])

    return (
        <div className={`overflow ${open}`}>

            <div className='modal-order-container'>
                <div className='header-modal flex-betwen flex-wrap'>
                    <div className='close-container'>
                        <div className='close-modal' onClick={() => { modaClose() }}></div>
                    </div>
                    <div className='title grow'>{appeal.name}</div>
                </div>
                <div className='body-modal'>
                    <form onSubmit={(e) => { createOrder(e) }}>
                        <div className='container'>
                            <div className='title-modal pt-40 pb-20'>Выберите область помещения</div>
                            <div className='flex-start flex-wrap area-wrap pb-20'>
                                {area.map((el, index) => {
                                    return (
                                        <div className='area-item-radio image-radio' key={index}>


                                            <input type='radio' id={`radio${index}`} name='area' defaultChecked={index == 0 ? 'checked' : ''} value={el.areaRoomId} onChange={() => { setValue('areaRoomId', el.areaRoomId) }} />
                                            <label htmlFor={`radio${index}`}>
                                                <div className='area-img'>
                                                    <img src={el.icon} alt={el.name} />
                                                </div>
                                                <div className='area-title pt-20'>
                                                    {el.name}
                                                </div>
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='hr'></div>
                            <div id='scroolBlock1'></div>
                            <div className='title-modal pt-20 pb-20'>Выберите тип обращения</div>

                            <div className='error'>{form.error.appealTypeId}</div>
                            <div className='appeal-types-wrap pb-20'>
                                {appealtype.map((el, index) => {
                                    return (

                                        <div className='appeal-type-item radius-radio' key={index}>

                                            <input type='radio' id={`radioappealtype${index}`} name='appealtype' value={el.name} onChange={() => { setValue('appealTypeId', el.appealTypeId) }} />
                                            <label htmlFor={`radioappealtype${index}`}>
                                                {el.name}
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='hr'></div>
                            <div className='title-modal pt-20 pb-20'>Описание заявки</div>
                            <div id='scroolBlock2'></div>
                            <div className={`input-block ${form.focus.description}`}>
                                <textarea name='description' id='description' rows='10' value={form.value.description} onChange={(e) => { setValue('description', e.target.value) }} placeholder='Описание заявки'></textarea>
                                <span className='error'>{form.error.description}</span>
                            </div>
                            <div className='input-block pb-20 input-files-block'>
                                <div className='files-wrap flex-start flex-wrap'>
                                    {form.value.icon.map((el, index) => {
                                        return (
                                            <div className='prevyu-image' key={index}>
                                                <span className='remove' onClick={() => { removeImage(index) }}>&times;</span>
                                                <img src={el} alt="Картинка" />
                                            </div>
                                        )

                                    })}
                                </div>
                                <label className='input-files'>
                                    <input type='file' multiple onChange={(e) => { inputFiles(e) }} accept="image/*" />
                                    <span>Прикрепить файлы</span>

                                </label>
                            </div>
                            <div className='hr'></div>
                            <div className='title-modal pt-20 pb-20'>Контактные данные</div>
                            <div className='orange-label'>
                                <div className='pt-20'></div>
                                <div className='input-block focus'>

                                    <input id='address' type='text' placeholder='Адрес' readOnly={true} value={form.value.address} />
                                    <label>Адрес</label>

                                </div>
                                <div className='pt-40'></div>
                                <div className='input-block focus'>

                                    <select onChange={(e) => { setValue('personalAccountId', e.target.value); setAddress(e.target.value) }}>
                                        {shects.map(

                                            (el, index) => {
                                                return (
                                                    <option key={index} value={el.personalAccountId}>{el.account}</option>
                                                )
                                            }
                                        )}
                                    </select>
                                    <label>Выберите счет</label>
                                </div>
                                <div className='pt-40'></div>
                                <div className={`input-block ${form.focus.contactPerson}`}>
                                    <input id='UserName' type='text' onChange={(e) => { setValue('contactPerson', e.target.value) }} value={form.value.contactPerson} />
                                    <label htmlFor='UserName'>Как к Вам обращаться?</label>
                                    <span className='error'>{form.error.contactPerson}</span>
                                </div>

                                <div className='pt-40'></div>
                                <div className={`input-block ${form.focus.phoneNumber}`}>

                                    <InputMask id="Phone" type="text" maskChar={null} mask="+7(999)999-99-99" onChange={(e) => { setValue('phoneNumber', e.target.value) }} value={form.value.phoneNumber} />
                                    <label htmlFor='Phone'>Телефон</label>
                                    <span className='error'>{form.error.phoneNumber}</span>
                                </div>
                                <div className='pt-10'></div>
                                <div className='pt-40'></div>
                                <div className='input-block'>
                                    <button type="submit" className='btn btn-orange'>Создать заявку</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    )
}
export default forwardRef(ModalAddOrder2);