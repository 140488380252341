import React, { useState, useEffect } from 'react';

import Header from '../Elements/Header';
import { Link } from 'react-router-dom';
import Get from '../Config/Get';
import Store from '../Store/Store';
import Config from '../Config/Config';
import ChatForm from '../Elements/ChatForm';
export const ChatIndex = () => {
    let config = new Config();




    useEffect(() => {


    }, [])
    return (
        <div className='home-page menu-container'>
            <Header data={{ title: 'Задать вопрос' }} nazad={true} />
            <div className='container'>

            </div>

            <ChatForm />
        </div>
    )
}
export default ChatIndex;