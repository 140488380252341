import React, { useState, useEffect } from 'react';
export const InputPass = (props) => {
    const [type, setType] = useState('password');

    const changeType = () => {
        if (type == 'password') {
            setType('text');
        } else {
            setType('password');
        }
    }
    return (
        <div className={`input-block ${type} ${props.focus}`}>
            <span className='eye' onClick={() => { changeType() }}></span>
            <input id={props.id} type={type} value={props.value} onChange={(e) => { props.onChange(e) }} />
            <label htmlFor={props.id}>{props.label}</label>
            <span className='error'>{props.error}</span>
        </div>);
}
export default InputPass;