import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Config from '../Config/Config';
export const ModalDeleteLs = (props, ref) => {
    const [open, setOpen] = useState('');
    useImperativeHandle(ref, () => ({ modaOpen }));
    const [title, setTitle] = useState('');
    const [ls, setLs] = useState('');
    const [address, setAddress] = useState('');
    const [func, setFunc] = useState(null)
    const [personalAccountId, setpersonalAccountId] = useState(null)
    let config = new Config();
    const modaOpen = (data) => {
        setOpen('open');

        if (data.hasOwnProperty('account')) {

            setLs(data.account);
        }
        if (data.hasOwnProperty('personalAccountId')) {

            setpersonalAccountId(data.personalAccountId);
        }
        if (data.hasOwnProperty('address')) {

            setAddress(data.address);
        }
        if (data.hasOwnProperty('func')) {

            setFunc(data);
        }
        console.log(func);
    }
    const propsFunc = () => {

        func.func(personalAccountId);
        modaClose();
    }

    const modaClose = () => {
        setOpen('');
    }
    return (
        <div className={`overflow ${open}`}>
            <div className='modal-container'>
                <div className='flex-betwen flex-wrap header-modal'>

                    <div className='title grow'>Вы действительно хотите удалить этот л/с?</div>
                    <div className='close-container'>
                        <div className='close-modal' onClick={() => { modaClose() }}>&times;</div>
                    </div>
                </div>
                <div className='body-modal pt-20'>
                    <div className='pt-20'></div>
                    <div className='modal-desc ls'>
                        Л/C {config.SchetFormat(ls)}
                    </div>
                    <div className='modal-desc address'>
                        {address}
                    </div>
                    <div className='pt-20'></div>
                    <div className="grey-text">При удалении, все Ваши данные, связанные с этим лицевым счётом, будут потеряны</div>
                    <div className='pt-20'></div>
                    <div className='flex-betwen flex-button'>
                        <button className='btn btn-green' onClick={() => { modaClose() }}>Нет</button>
                        <button className='btn btn-red' onClick={() => { propsFunc() }}>Да</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default forwardRef(ModalDeleteLs);