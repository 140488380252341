import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./Css/normalize.min.css";
import "./Css/flex.setting.css";

import "./Css/index.css";
import "./fonts/TTInterfaces-Light.ttf";
import "./fonts/TTInterfaces-Medium.ttf";
import "./fonts/TTInterfaces-Bold.ttf";

import Router from "./Router.js";
import reportWebVitals from "./reportWebVitals.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
const meta = document.createElement("meta");
meta.setAttribute(
  "content",
  "width=device-width, initial-scale=1, maximum-scale=0.99, user-scalable=0"
);
meta.setAttribute("name", "viewport");
document.getElementsByTagName("head")[0].appendChild(meta);
root.render(<BrowserRouter><Router /></BrowserRouter>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
