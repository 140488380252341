import React, { useState, useEffect, useRef } from 'react';

import Header from '../Elements/Header';
import { Link } from 'react-router-dom';
import Get from '../Config/Get';
import Menu from '../Elements/Menu';
import Store from '../Store/Store';
import Config from '../Config/Config';
import { jwtDecode } from "jwt-decode";
import ModalAddOrder1 from '../Elements/ModalAddOrderService1';
import ModalNoSchet from '../Elements/ModalNoSchet';
export const Services = () => {
    const modNoschet = useRef(null);
    const modal1 = useRef(null);
    const [shects, setShets] = useState([]);
    let config = new Config();

    let tokenData = JSON.parse(localStorage.getItem('token'));
    // console.log(tokenData);
    let tokenDataDecode = {};
    if (typeof tokenData == 'object') {
        tokenDataDecode = jwtDecode(tokenData.token);
        // console.log(tokenDataDecode);
    }
    const [status, setStatus] = useState('active');

    const [orders, setOrders] = useState([])
    const getOrders = async () => {

        if (Store.orders.time < Date.now()) {
            let tokenData = JSON.parse(localStorage.getItem('token'));
            tokenDataDecode = jwtDecode(tokenData.token);
            // console.log(tokenDataDecode.user_id);
            let data = await Get.get('api/requests?userId=' + tokenDataDecode.user_id);
            Store.orders.data = data.data;
            Store.orders.time = Date.now() * 60 * 60;



        }
        setOrders(Store.orders.data);
    }
    const openModal1 = () => {
        if (shects.length > 0) {
            modal1.current.modaOpen();
        } else {
            ModalNoSchetOpen();
        }

    }
    const scroll = () => {
        setTimeout(function () {
            let element = document.getElementById('scrollBlock');

            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }

        }, 100);
    }
    const getSchets = async () => {
        let data = [];
        if (Store.schets.time < Date.now()) {
            data = await Get.get('api/personal-accounts');
            if (data.status !== 404) {
                Store.schets.data = data.data;
                Store.schets.time = Date.now() * 60 * 60 * 24 * 7;
            }
        }
        setShets(Store.schets.data);
        if (Store.schets.data.length == 0) {
            ModalNoSchetOpen();
        }
    }
    const ModalNoSchetOpen = () => {
        if (modNoschet.current !== null) {
            modNoschet.current.modaOpen();
        }

    }
    useEffect(() => {
        getOrders();
        getSchets();
    }, [])
    return (
        <>
            <div className='orders-page menu-container'>
                <Header data={{ title: 'Заявки' }} />

                <div className='tab-links-wrap pt-20'>
                    <div className='container'>
                        <ul className='tab-links flex-betwen'>
                            <li>
                                <Link to='/orders'>Заявки</Link>
                            </li>
                            <li>
                                <Link className="active" to='/services'>Услуги</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='container pt-20'>
                    <div className='btns-container-status flex-start'>
                        <button type='button' className={`btn ${status == 'active' ? 'btn-orange' : 'btn-shadof'}`} onClick={() => setStatus('active')}>Активные</button>
                        <button type='button' className={`btn ${status == 'complete' ? 'btn-orange' : 'btn-shadof'}`} onClick={() => setStatus('complete')}>Выполненные</button>
                    </div>
                </div>
                <div className='container pt-20'></div>
                <div className='order-container container'>

                    {orders.length > 0 ? orders.map((el, index) => {

                        if (status == 'active' && el.status.name !== 'Выполнено') {
                            return (
                                <div className='order-item' key={index}>

                                    <Link to={`/order?id=${el.requestId}`} key={index} className='order-item-link'>
                                        <div className='flex-start flex-wrap'>
                                            <div className='title'>Заявка № {el.requestNumber}</div>

                                            <div className='date'>{config.dateFormatTimestapTime(el.createdOn)}</div>
                                        </div>
                                        <div className='pt-10'></div>
                                        <div className='status-block'>
                                            <div className='status-container' style={{ background: el.status.color }}>
                                                <div className='status flex-start'>
                                                    <img src={el.status.icon} />
                                                    <div className='status-title'>{el.status.name}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='pt-15'></div>
                                        <div className='hr'></div>
                                        <div className='title-bilding'>
                                            {el.appealCategory.name}
                                        </div>
                                        <div className='pt-15'></div>
                                        <div className='message'>
                                            {el.description}
                                        </div>
                                    </Link>
                                    <Link to={`/chat?order=${el.requestId}`} className='chat-link'></Link>
                                </div>
                            );
                        }
                        if (status == 'complete' && el.status.name == 'Выполнено') {
                            return (
                                <div className='order-item' key={index}>

                                    <Link to={`order?id=${el.requestId}`} key={index} className='order-item-link'>
                                        <div className='flex-start flex-wrap'>
                                            <div className='title'>Заявка № {el.requestNumber}</div>

                                            <div className='date'>{config.dateFormatTimestapTime(el.createdOn)}</div>
                                        </div>
                                        <div className='pt-10'></div>
                                        <div className='status-block'>
                                            <div className='status-container' style={{ background: el.status.color }}>
                                                <div className='status flex-start'>
                                                    <img src={el.status.icon} />
                                                    <div className='status-title'>{el.status.name}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='pt-15'></div>
                                        <div className='hr'></div>
                                        <div className='title-bilding'>
                                            {el.appealCategory.name}
                                        </div>
                                        <div className='pt-15'></div>
                                        <div className='message'>
                                            {el.description}
                                        </div>
                                    </Link>
                                </div>
                            );
                        }



                    }) : <div className='no-orders-container container'><div className="text-alert">Здесь будут отображаться ваши заявки и услуги. Вы можете создать их здесь</div></div>}
                </div>
                <Menu link='/orders' />

            </div>
            <ModalAddOrder1 ref={modal1} getOrders={() => { getOrders() }} />
            <div className='plus-orders' onClick={() => openModal1()}></div>
            <ModalNoSchet ref={modNoschet} />
        </>
    )
}
export default Services;