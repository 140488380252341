const Validate = {

    Email: (value) => {
        let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (reg.test(value)) {
            return false;
        } else {
            return true;
        }
    },
    Cirilic: (value) => {
        let reg = /[а-яА-Я]/i;
        if (reg.test(value)) {
            return false;
        } else {
            return true;
        }
    },
    Count: (value, min = 5, max = 25) => {
        if (value.length < min) {
            return true;
        }
        if (value.length > max) {
            return true;
        }
        return false;
    },
    Empty: (value) => {
        if (value == undefined) {
            return true;
        }
        value = value.trim();
        if (value === '') {
            return true;
        } else {
            return false;
        }
    },
    Phone: (value) => {
        return value.replace(/\D/g, '');
    }
}
export default Validate;