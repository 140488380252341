import React, { useState, useEffect } from 'react';
import Header from '../Elements/Header';
import { Link } from 'react-router-dom';
import InputPass from '../Elements/InputPass';
import InputMask from 'react-input-mask';
import Validate from '../Config/Validate';
export const Changepass = () => {
    const [form, setForm] = useState(
        {
            value: {
                phone: '',
                password: ''
            },

            error: {
                phone: '',
                password: ''
            },
            focus: {
                phone: '',
                password: ''
            }
        }
    );
    const setValue = (name, value) => {

        setForm({
            ...form,
            value: {
                ...form.value,
                [name]: value
            }, focus: {
                ...form.focus,
                [name]: value !== '' ? 'focus' : ''
            }, error: {
                ...form.error,
                [name]: ''
            }
        })
    }
    const sendForm = async (e) => {
        e.preventDefault();
        if (Validate.Empty(form.value.phone)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    phone: 'Поле обязательно для заполнения'
                },
                focus: {
                    ...form.focus,
                    phone: 'focus error'
                }
            });
            return;
        }
        if (Validate.Count(form.value.phone, 16, 16)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    phone: 'Не корректный номер'
                },
                focus: {
                    ...form.focus,
                    phone: 'focus error'
                }
            });
            return;
        }
        if (Validate.Empty(form.value.password)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    password: 'Поле обязательно для заполнения'
                },
                focus: {
                    ...form.focus,
                    password: 'focus error'
                }
            });
            return;
        }
        if (Validate.Count(form.value.password, 6, 12)) {
            setForm({
                ...form,
                error: {
                    ...form.error,
                    password: 'Поле должно содержать не менее 6 и не более 12 символов'
                },
                focus: {
                    ...form.focus,
                    password: 'focus error'
                }
            });
            return;
        }
    }
    return (
        <div className='login-page'>
            <Header data={{ title: 'Новый пароль' }} nazad={true} />
            <div className='pt-40'></div>
            <div className='container'>
                <div className='changepass-form'>
                    <form onSubmit={(e) => { sendForm(e) }}>
                        <div className="grey-text">Введите телефон, который Вы указали при регистрации. На Вашу электронную почту, указанную при регистрации, будет выслан новый пароль.</div>
                        <div className='pt-40'></div>
                        <div className={`input-block ${form.focus.phone}`}>
                            <InputMask id="Phone" type="text" maskChar={null} mask="+7(999)999-99-99" value={form.value.phone} onChange={(e) => { setValue('phone', e.target.value) }} />
                            <label htmlFor="Phone">Телефон</label>
                            <span className='error'>{form.error.phone}</span>
                        </div>

                        <div className='input-block'>
                            <button type='submit' className='btn btn-orange'>Отправить</button>
                        </div>

                    </form>
                </div>
                <div className='pt-15'></div>
                <div className='input-block'>
                    <Link to='/login' className='btn btn-white'>Войти</Link>
                </div>
            </div>
        </div>
    )
}
export default Changepass;