
import Config from "./Config";
import Socket from '../Config/Socket';
const Get = {

    config: new Config(),
    socket: Socket,
    refresh: 0,
    post: async (data, method) => {
        let token = localStorage.getItem('token');
        let tokenheader = ''
        if (token) {
            token = JSON.parse(token);
            tokenheader = token.token
        }
        let block = document.getElementById('GlobalLoader');
        block.classList.add('fade');
        setTimeout(() => {
            block.classList.add('in');
        }, 500);
        let res = { type: 'error', status: 500 };
        try {
            let response = await fetch(Get.config.url + '/' + method, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                    "Authorization": "Bearer " + tokenheader,
                },
                body: JSON.stringify(data),
            });



            res = await response.json().catch(() => {

            });

            if (response.ok && res == null) {
                res = { type: 'success', status: 201 };
            }


        } catch (e) {

        }
        block.classList.remove('in');
        setTimeout(() => {
            block.classList.remove('fade');
        }, 500);
        return res;
    },
    patch: async (data, method) => {
        let token = localStorage.getItem('token');
        let tokenheader = ''
        if (token) {
            token = JSON.parse(token);
            tokenheader = token.token
        }
        let block = document.getElementById('GlobalLoader');
        block.classList.add('fade');
        setTimeout(() => {
            block.classList.add('in');
        }, 500);
        let res = { type: 'error', status: 500 };
        try {
            let response = await fetch(Get.config.url + '/' + method, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                    "Authorization": "Bearer " + tokenheader,
                },
                body: JSON.stringify(data),
            });



            res = await response.json().catch(() => {

            });
            if (response.ok && res == null) {
                res = { type: 'success', status: 204 };
            }



        } catch (e) {

        }
        block.classList.remove('in');
        setTimeout(() => {
            block.classList.remove('fade');
        }, 500);
        return res;
    },
    put: async (data, method) => {
        let token = localStorage.getItem('token');
        let tokenheader = ''
        if (token) {
            token = JSON.parse(token);
            tokenheader = token.token
        }
        let block = document.getElementById('GlobalLoader');
        block.classList.add('fade');
        setTimeout(() => {
            block.classList.add('in');
        }, 500);
        let res = { type: 'error', status: 500 };
        try {
            let response = await fetch(Get.config.url + '/' + method, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                    "Authorization": "Bearer " + tokenheader,
                },
                body: JSON.stringify(data),
            });




            if (response.ok) {
                res = { type: 'success', status: response.status };
            }



        } catch (e) {

        }

        block.classList.remove('in');
        setTimeout(() => {
            block.classList.remove('fade');
        }, 500);
        return res;
    },
    delete: async (method) => {
        let token = localStorage.getItem('token');
        let tokenheader = ''
        if (token) {
            token = JSON.parse(token);
            tokenheader = token.token
        }
        let block = document.getElementById('GlobalLoader');
        block.classList.add('fade');
        setTimeout(() => {
            block.classList.add('in');
        }, 500);
        let res = { 'type': 'error', status: 500 };

        try {
            let response = await fetch(Get.config.url + '/' + method, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                    "Authorization": "Bearer " + tokenheader,
                }
            });





            res = await response.json().catch(() => {

            });
            if (response.ok) {
                res = { type: 'success', status: 204 };
            }



        } catch (e) {
            //Get.refreshToken();
            // res = Get.get(method)
            if (e) {
                localStorage.clear();
                window.location.href = '/login'
            }
        }
        block.classList.remove('in');
        setTimeout(() => {
            block.classList.remove('fade');
        }, 500);
        return res;
    },
    get: async (method) => {
        let token = localStorage.getItem('token');
        let tokenheader = ''
        if (token) {
            token = JSON.parse(token);
            tokenheader = token.token
        }
        let block = document.getElementById('GlobalLoader');
        block.classList.add('fade');
        setTimeout(() => {
            block.classList.add('in');
        }, 500);
        let res = { 'type': 'error', status: 500 };

        try {
            let response = await fetch(Get.config.url + '/' + method, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                    "Authorization": "Bearer " + tokenheader,
                }
            });





            res = await response.json().catch(() => {

            });

            if (response.ok && res == null) {
                res = { type: 'success', status: 204, data: [] };
            }


        } catch (e) {
            //Get.refreshToken();
            // res = Get.get(method)
            if (e) {
                localStorage.clear();
                window.location.href = '/login'
            }
        }
        block.classList.remove('in');
        setTimeout(() => {
            block.classList.remove('fade');
        }, 500);
        return res;
    },
    refreshToken: async () => {

        let token = localStorage.getItem('token');
        let tokenheader = ''
        let refreshToken = '';
        if (token) {
            token = JSON.parse(token);
            tokenheader = token.token;
            refreshToken = token.refreshToken;

        }
        let block = document.getElementById('GlobalLoader');
        //api/identity/token/refresh
        try {
            let response = await fetch(Get.config.url + '/api/identity/token/refresh', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
                body: JSON.stringify({
                    token: tokenheader,
                    refreshToken: refreshToken,
                }),
            });
            res = await response.json().catch(() => {

            });
            localStorage.setItem('token', JSON.stringify(res));
        } catch (e) {
            console.log(e);
        }


        block.classList.add('fade');
        setTimeout(() => {
            block.classList.add('in');
        }, 500);
        let res = { 'type': 'error', status: 500 };
    }
};

export default Get;