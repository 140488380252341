const Store = {
    news: {
        time: 0,
        data: []
    },
    orders: {
        time: 0,
        data: []
    },
    area: {
        time: 0,
        data: []
    },
    appeal: {
        time: 0,
        data: []
    },
    appealtypes: {
        time: 0,
        data: []
    },
    user: {
        time: 0,
        data: []
    },
    schets: {
        time: 0,
        data: []
    },
    customOrder: {
        time: 0,
        data: []
    },
    chat: {
        time: 0,
        data: [

        ]
    },
    phonetoken: {
        time: 0,
        data: null
    }
    // ... other store properties here
}
export default Store;