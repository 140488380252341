import { Outlet, Navigate, useLocation } from 'react-router-dom';


export const PrivateRoute = () => {
  var Autch = localStorage.getItem("token");
  const location = useLocation();



  return Autch !== null ? (
    <div className="outlet-container">
      <Outlet />
      {/* <div id="SocketLoader" className="socket-loader">Подключение</div> */}
    </div>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};
export default PrivateRoute;
