import React, { useState, useEffect } from 'react';

import Header from '../Elements/Header';
import { Link } from 'react-router-dom';
import Get from '../Config/Get';
import Menu from '../Elements/Menu';
import Store from '../Store/Store';
import Config from '../Config/Config';
export const News = () => {
    let config = new Config();
    const [news, setNews] = useState([])
    const [novost, setNovost] = useState(
        {
            content: "",
            createdOn: "",
            publishedDate: "",
            title: "Новость"
        }
    );
    const getNews = async () => {
        let news = [];
        if (Store.news.time < Date.now()) {
            let data = await Get.get('api/news?offset=0&count=10');
            Store.news.data = data.data;
            Store.news.time = Date.now() * 60 * 60 * 24;
            setNews(data.data);
            news = data.data;
        } else {
            setNews(Store.news.data);
            news = Store.news.data;
        }
        const urlParams = new URLSearchParams(window.location.search);
        const news_id = urlParams.get('id');

        news.map((el) => {

            if (el.newsId == news_id) {
                setNovost(el);
            }
        });


    }



    useEffect(() => {
        const as = async () => {
            await getNews();

        }
        as();


    }, [])
    return (
        <div className='home-page menu-container'>
            <Header data={{ title: '' }} nazad={true} />
            <div className='container'>
                <div className='news-page-container'>
                    <div className='image-container'>
                        <img src="/news.png" alt={novost.title} />
                    </div>
                    <div className='date'>
                        {config.dateFormatTimestap(novost.publishedDate)}
                    </div>
                    <div className='title'>{novost.title}</div>
                    <div className='content'>
                        {novost.content}
                    </div>
                </div>
            </div>
            <Link to="indexchat" className='chat-index-link'></Link>
            <Menu link='/' />

        </div>
    )
}
export default News;