import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Get from '../Config/Get';
import Config from '../Config/Config';
export const ChatForm = (props, ref) => {
    let config = new Config();
    const [form, setForm] = useState(
        {
            value: {
                message: '',
                file: ''
            }
        }
    );
    const changeTextarea = (e) => {
        let message = e.target.innerHTML;
        setForm({
            ...form,
            value: {
                ...form.value,
                message: message
            }
        });

    }

    const inputFiles = async (e) => {
        let files = [...e.target.files];
        let images = await Promise.all(files.map(f => { return readAsDataURL(f) }));
        console.log(images)
        setForm({
            ...form,
            value: {
                ...form.value,
                file: images[0].data
            }
        })
    }
    const readAsDataURL = (file) => {
        return new Promise((resolve, reject) => {
            let fileReader = new FileReader();
            fileReader.onload = function () {
                return resolve({ data: fileReader.result, name: file.name, size: file.size, type: file.type });

            }
            fileReader.readAsDataURL(file);
        })
    }

    const removeImage = () => {
        setForm({
            ...form,
            value: {
                ...form.value,
                file: ''
            }
        });
    }
    const submit = (e) => {
        e.preventDefault();
        if (typeof props.onSubmit == 'function') {
            props.onSubmit(form);
            setForm({
                ...form,
                value: {
                    ...form.value,
                    file: '',
                    message: ''
                }
            });
            let textarea = document.getElementById('TextArea');
            textarea.innerHTML = '';
        }
    }

    return (
        <div className='fixed-menu'>
            <form onSubmit={(e) => { submit(e) }}>
                <div className='chat-form'>

                    <div className='prev-container'>
                        {form.value.file !== '' ? <div className='prevyu'>
                            <div className='close-file' onClick={() => { removeImage() }}>&times;</div>
                            <img src={form.value.file} alt="Файл" />
                        </div> : ''}
                    </div>
                    <div className='chat-form-wrap flex-betwen flex-wrap'>
                        <div className='input-file-block'>
                            <label className='input-file'>
                                <input type="file" accept="image/*" onChange={(e) => { inputFiles(e) }} />

                            </label>
                        </div>
                        <div className='message-block grow'>
                            <div className='text-area' id='TextArea' contentEditable="true" data-ph="Напишите сообщение" onKeyUp={(e) => { changeTextarea(e) }}></div>
                        </div>
                        <div className='send-btn-block'>
                            <button type='submit' className='send-chat'></button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
export default forwardRef(ChatForm);