import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

export const Header = (props) => {
    const data = props.data;
    const nazad = props.nazad == undefined ? false : true;
    const title = data.title !== undefined ? data.title : 'Заголовок не указан';
    let navigate = useNavigate();
    const historyBack = () => {
        navigate(-1);
    }
    return (

        <div className='fixed-header'>
            <div className='flex-start flex-wrap'>

                {nazad !== false ? <div className='link-nazad' onClick={() => historyBack()}></div> : ''}
                <div className='title'>{title}</div>
            </div>
        </div>
    )
}
export default Header;